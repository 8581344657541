import React from 'react';
import { navigate } from 'gatsby';
import { TmpLoading } from '@tempo/tempo-design-system-core/dist';

function Roche() {
  const isWindow = typeof window !== 'undefined';

  React.useEffect(() => {
    navigate('/vivaoseumelhor/roche');
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isWindow && <TmpLoading label="Carregando..." />}
    </div>
  );
}

export default Roche;
